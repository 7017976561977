/*

  __     __           _         ____ _       _           _   ____  _         _
  \ \   / /__ _ __ __| | ___   / ___| | ___ | |__   __ _| | / ___|| |_ _   _| | ___  ___
   \ \ / / _ \ '__/ _` |/ _ \ | |  _| |/ _ \| '_ \ / _` | | \___ \| __| | | | |/ _ \/ __|
    \ V /  __/ | | (_| |  __/ | |_| | | (_) | |_) | (_| | |  ___) | |_| |_| | |  __/\__ \
     \_/ \___|_|  \__,_|\___|  \____|_|\___/|_.__/ \__,_|_| |____/ \__|\__, |_|\___||___/
                                                                       |___/

*/

:root {
  --header-height: 60px;
}

// Kendo Fluent Theme - Overrides
html,
body {
  background-color: var(--tb-kendo-neutral-10);
  padding: 0;
  margin: 0;
  height: 100%;
}

// Treelist row backgrounds
.background-row-new {
  background-color: lightgreen !important;
}

.background-row-changed {
  background-color: lightblue !important;
}

.background-row-disposed {
  background-color: lightcoral !important;
}

// Webcam

webcam video {
  max-width: 100%;
}

.mat-checkbox-inner-container {
  width: 24px !important;
  height: 24px !important;
  .mat-checkbox-frame {
    border-radius: 5px;
    overflow: hidden;
  }
  .mat-checkbox-background {
    border-radius: 3px;
    overflow: hidden;
    svg {
      height: 15px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.verde-select {
  color: #424242;
  font-size: 0.9rem;
  border-radius: 3px 0 0 3px;
  border-color: #ebebeb;
  padding: 4px 8px;
  outline-color: #e2e2e2;
}

mat-checkbox {
  span {
    margin-bottom: 0px !important;
  }
}

.custom-toolbar {
  display: flex;
  height: 40px;
  width: 100%;
  background-color: var(--tb-kendo-neutral-20);
  line-height: 10px;
  border-bottom: inset;
  border-bottom-width: 1px;
  padding: 3px 8px;
  color: #656565;

  div {
    display: flex;
    width: 100%;
    align-items: center;

    .toolbar-content-left {
      margin-left: 1rem;
      font-weight: bold;
      font-size: 16px;
      vertical-align: middle;
      width: 100%;
    }

    .toolbar-content-right {
      float: right;
      font-size: 16px;
      vertical-align: middle;
      display: inline-flex;
    }
  }
}

a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.container {
  margin: 25px;
}

//Init modal

.loading-modal-content {
  background-color: var(--tb-kendo-neutral-10);

  &.loading-modal-grid {
    display: grid;
    grid-template-columns: 2fr 1fr;
    height: 440px;
  }

  kendo-skeleton,
  img {
    width: 100%;
    height: 100%;
  }

  .social_media_rss_container {
    max-height: 100%;

    kendo-skeleton,
    iframe {
      width: 100%;
      height: 100%;
    }
  }

  .loading-rss-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 186px;
  }

  @media screen and (max-width: 586px) {
    &.loading-modal-grid {
      grid-template-columns: 1fr;
    }

    .manual_docs_modal_grid {
      grid-template-columns: 1fr;
    }
  }
}

.loading-modal-footer {
  background-color: var(--tb-kendo-neutral-10);
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e0e0e0;
  width: 100%;

  .loading-right-section {
    //margin-right: 1rem;
    margin-right: 0.5rem;
    margin-bottom: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .loading-left-section {
    margin-left: 1rem;
    width: 100px;
    height: 100px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  @media screen and (max-width: 586px) {
    .loading-right-section {
      margin-bottom: 0;
      justify-content: center;
    }

    .loading-left-section {
      width: 50px;
      height: 50px;
    }
  }
}

//Manual Documents
.link_class {
  text-decoration: underline;
}

.link_class:hover {
  cursor: pointer;
}

.manual_docs_modal_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
}

.details_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
  max-width: 420px;
}

.details_grid > p {
  white-space: nowrap;
}

.details_background {
  min-width: 250px;
  max-width: 600px;
  background-color: var(--tb-kendo-neutral-10);
  border: solid;
  border-radius: 25px;
  border-width: 1px;
  border-color: #e1dfdd;
  padding: 20px;
}

.add_icon {
  vertical-align: middle;
}

.button_text {
  vertical-align: middle;
}

.edit_button {
  user-select: none;
  cursor: pointer;
  font-size: 25px;
  color: #6264a7;
  margin: 5px;
}

.edit_button:hover {
  color: #464775;
}

.refresh_button {
  user-select: none;
  cursor: pointer;
  font-size: 30px;
  color: #8e8e8e;
  margin: 5px;
}

.refresh_button:hover {
  color: #6264a7;
}

.new_refresh_button {
  float: right;
  user-select: none;
  cursor: pointer;
  font-size: 30px;
  color: #8e8e8e;
  margin: 8px 10px 0px 2px;
}

.new_refresh_button:hover {
  color: #6264a7;
}

.collapse_icon {
  float: right;
  user-select: none;
  cursor: pointer;
  font-size: 30px;
  color: #8e8e8e;
  margin: 20px 0 0 0;
}

.collapse_icon:hover {
  color: #6264a7;
}

.close_button {
  float: right;
  cursor: pointer;
  font-size: 30px;
  color: #8e8e8e;
}

.btn-micro {
  background: #6264a7;
  color: var(--tb-kendo-neutral-10);
}

/*Styling for default teams*/

.card-body,
a {
  color: var(--tb-kendo-neutral-10);
}

.myCardClass:hover {
  opacity: 0.8;
  cursor: pointer;
}

//Header Classes
.expansion_header_flex {
  display: flex;
  align-items: center;
  height: 40px;
  width: 100%;
  padding: 5px 10px;
  border-top: solid 0.1px #e1dfdd;
  border-bottom: solid 0.1px #e1dfdd;
}

.expansion_header {
  min-height: 40px;
  max-height: 40px;
  border-top: solid;
  border-bottom: solid;
  border-top-width: 0.1px;
  border-bottom-width: 0.1px;
  border-top-color: #e1dfdd;
  border-bottom-color: #e1dfdd;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.issue_icon {
  user-select: none;
  cursor: pointer;
  margin: 14px 5px;
}

.refresh_icon {
  user-select: none;
  cursor: pointer;
  float: right;
}

.help_icon {
  user-select: none;
  cursor: pointer;
  margin: 14px 5px;
}

.policy_icon {
  user-select: none;
  cursor: pointer;
  margin: 14px 5px;
}

.user_impersonation {
  display: flex;
  align-items: center;
  min-width: 300px;
  margin-left: 10px;
}

.back_button {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

@media screen and (max-width: 500px) {
  .user_impersonation {
    max-width: 160px;
    min-width: 160px;
  }
}

.confirmation-modal-footer {
  display: inline-flex;
  width: 100%;
}

// fix for tabstrip overlay issue with kendo modals
.k-tabstrip-items-wrapper {
  z-index: auto !important;
}

.k-tabstrip-top > .k-content {
  margin-top: 1px !important;
}

.k-tabstrip-right > .k-content {
  margin-right: 1px !important;
}

.k-tabstrip-bottom > .k-content {
  margin-bottom: 1px !important;
}

.k-tabstrip-left > .k-content {
  margin-left: 1px !important;
}

//Pivot Grid Code

table.k-pivotgrid-table {
  //width: unset !important;
  table-layout: fixed;
}
.k-pivotgrid-cell {
  border-style: solid !important;
  border-width: 1px !important;
}

.k-pivotgrid-cell ng-star-inserted {
  width: 60px !important;
}

// kendo-popover styling

.k-popover-body {
  background-color: #6264a7 !important;
  color: white !important;
}

.k-popover-header {
  background-color: #6264a7 !important;
  color: white !important;
}

.k-popover-inner {
  background-color: #6264a7 !important;
  color: white !important;
}

.k-popover-callout {
  background-color: #6264a7 !important;
}

.side-panel-bottom-buttons {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px 10px 20px;
  background: white;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}
